import styled from '@emotion/styled';
import { createMuiTheme } from '@material-ui/core/styles';
import { space, background } from 'styled-system';

const theme = createMuiTheme();

const PageContent = styled('div')(
  {
    padding: 16,
    flex: 1,
    '&.no-padded': {
      padding: 0
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 425,
      margin: 'auto'
    }
  },
  space,
  background
);

export default PageContent;
