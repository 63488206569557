import get from 'lodash/get';

const selectBrandState = state => get(state, 'brand');
export const selectBrands = state => selectBrandState(state).data;
export const selectBrandsStatus = state =>
  selectBrandState(state).getBrandStatus;

export const selectBrandsQuery = state => selectBrandState(state).query;
export const selectActiveCategory = state =>
  selectBrandState(state).activeCategory;
export const selectBrandsQueryStatus = state =>
  selectBrandState(state).getBrandsByQuery;
export const selectBrandLists = state =>get(selectBrandState(state),'brandLists')
export const selectGetBrandById = state=> get(selectBrandState(state),'getBrandById')
