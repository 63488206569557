import TYPES from './types';
import cloudApi from '../../api/cloudApi';

export const getAllBrands = () => dispatch => {
  return dispatch({
    type: TYPES.GET_BRANDS,
    payload: cloudApi.getAllBrands()
  });
};

export const getBrandsByCategory = (queryPath, data) => dispatch => {
  return dispatch({
    type: TYPES.GET_BRANDS_BY_CATEGORY,
    payload: { queryPath, data }
  });
};

export const changeActiveCategory = data => dispatch => {
  return dispatch({
    type: TYPES.CHANGE_ACTIVE_CATEGORY,
    payload: data
  });
};

export const getBrandById = brandId => dispatch =>{
  return dispatch({
    type: TYPES.GET_BRAND_BY_ID,
    payload: cloudApi.getBrandById(brandId)
  });
}
