import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import get from 'lodash/get';

import { navigate } from 'gatsby';

//hoc
import withTheme from '../hocs/withTheme';

//COMPONENTS
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import { PageContent } from 'components/layouts';
import { Text, Loader, NoResult, Image, Block } from 'components/atoms';
import { RewardCard, Modal } from 'components/molecules';

// REDUX
import { getRewards } from 'modules/reward/actions';
import {
  selectRewards,
  selectGetRewardsStatus
} from 'modules/reward/selectors';

// ICONS
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import appstore from 'assets/app-store.png';
import googleplay from 'assets/google-play.png';

const styles = theme => {
  return {
    layout: {
      width: 'auto',
      display: 'block', // Fix IE11 issue.
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    },
    paper: {
      marginTop: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    bannerBox: {
      width: '100%',
      position: 'relative'
    },
    brandData: {
      display: 'flex',
      position: 'absolute',
      top: 234,
      left: 25
    },
    backButton: {
      position: 'absolute',
      top: -126,
      left: -17
    },
    stampBox: {
      display: 'flex',
      padding: 20,
      paddingTop: 10,
      paddingBottom: 10,
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottomColor: '#e5e5e5e5',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid'
    },
    description: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: 275,
      '@media only screen and (max-width: 320px)': {
        maxWidth: 236
      }
    },
    rewardMargin: {
      marginTop: 25,
      marginBottom: 25
    },
    gradientBox: {
      height: 127,
      bottom: 0,
      top: 175,
      left: 0,
      right: 0,
      position: 'absolute',
      width: '100%'
    },
    downloadBox: {
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 200
    }
  };
};

class CardDetail extends React.Component {
  state = {
    rewardLists: null,
    showDownloadAppModal: false
  };

  componentDidMount() {
    const { rewards, brandDetail, brandShop } = this.props;
    if (brandDetail) {
      if (rewards[brandDetail.brand.id]) {
        this.setState({ rewardLists: rewards[brandDetail.brand.id] });
      } else {
        this.props.getRewards(brandDetail.brand.id).then(data => {
          this.setState({
            rewardLists: data.value
          });
        });
      }
    } else if (brandShop) {
      if (rewards[brandShop.id]) {
        this.setState({ rewardLists: rewards[brandShop.id] });
      } else {
        this.props.getRewards(brandShop.id).then(data => {
          this.setState({
            rewardLists: data.value
          });
        });
      }
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { brandDetail, brandShop, rewards } = this.props;
    const { brandDetail: nextBrand, brandShop: nextBrandShop } = nextProps;
    if (!brandDetail && nextBrand) {
      this.props.getRewards(nextBrand.brand.id).then(data => {
        this.setState({
          rewardLists: data.value
        });
      });
    }
    if (!brandShop && nextBrandShop) {
      if (rewards[nextBrandShop.id]) {
        this.setState({ rewardLists: rewards[nextBrandShop.id] });
      } else {
        this.props.getRewards(nextBrandShop.id).then(data => {
          this.setState({
            rewardLists: data.value
          });
        });
      }
    }
  }

  pickBrandToRender = () => {
    const { brandDetail, brandShop } = this.props;
    if (get(brandDetail, 'brand'))
      return { ...brandDetail.brand, point: brandDetail.point };
    else return { ...brandShop, point: 0 };
  };

  render() {
    const {
      classes,
      getRewardsStatus,
      brandDetail,
      backToMyCard,
      shouldRenderLoading,
      renderLoading,
      brandShop
    } = this.props;
    const { rewardLists, showDownloadAppModal } = this.state;
    if (shouldRenderLoading && !rewardLists) {
      return renderLoading();
    }
    if (!brandDetail && !brandShop) {
      return <PageContent />;
    }

    const brand = this.pickBrandToRender();
    return (
      <Block>
        <Image
          objectFit={'cover'}
          src={brand.cover}
          style={{
            height: 300,
            width: '100%'
          }}
        />
        <PageContent className={'no-padded'}>
          <div className={['classes.layout', null]}>
            <div className={['classes.paper', null]}>
              <div className={['classes.bannerBox', null]}>
                <Block
                  display="flex"
                  style={{
                    background: `linear-gradient(transparent,${
                      brand.gradientColor
                    })`
                  }}
                  justifyContent={'center'}
                  className={classes.gradientBox}
                />
                <div className={classes.brandData}>
                  <IconButton
                    className={classes.backButton}
                    onClick={() => window.history.back()}>
                    <KeyboardArrowLeft
                      style={{
                        fontSize: 40,
                        color:
                          brand.gradientColor === '#000000'
                            ? 'white'
                            : brand.gradientColor
                      }}
                    />
                  </IconButton>
                  <Image
                    style={{ width: 50, height: 50, borderRadius: 8 }}
                    src={brand.logo}
                  />
                  <div style={{ paddingLeft: 16, marginTop: -4 }}>
                    <Text
                      fontSize={4}
                      fontWeight={'extraBold'}
                      color={brand.textColor}
                      style={{ fontFamily: 'thai-sans-neue' }}>
                      {brand.name.toUpperCase()}
                    </Text>
                    <Text
                      mt={'-7px'}
                      color={'grey'}
                      fontSize={3}
                      fontWeight={500}
                      className={classes.description}
                      style={{ fontFamily: 'thai-sans-neue' }}>
                      {brand.description}
                    </Text>
                  </div>
                </div>
                {!brandShop && (
                  <div className={classes.stampBox}>
                    <Text.ThaiHeading fontSize={20} mt={'5px'}>
                      แสตมป์ของฉัน
                    </Text.ThaiHeading>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Text.ThaiHeading
                        fontSize={35}
                        style={{ paddingRight: 10 }}>
                        {brand.point || 0}
                      </Text.ThaiHeading>
                      <Text color="#a5a5a5" fontSize={16}>
                        PUMP
                      </Text>
                    </div>
                  </div>
                )}
                <div style={{ padding: 20 }}>
                  <Text.ThaiHeading fontSize={20}>ของรางวัล</Text.ThaiHeading>
                  {!rewardLists || getRewardsStatus.isPending ? (
                    <Loader />
                  ) : rewardLists.length > 0 ? (
                    rewardLists.map(rewardList => (
                      <div
                        key={rewardList.id}
                        className={classes.rewardMargin}
                        onClick={() =>
                          this.setState({ showDownloadAppModal: true })
                        }>
                        <RewardCard
                          primaryColor={brand.primaryColor || 'primary'}
                          title={rewardList.title}
                          rewardPoint={rewardList.point}
                          rewardImage={rewardList.image}
                        />
                      </div>
                    ))
                  ) : (
                    <NoResult />
                  )}
                </div>
              </div>
            </div>
            <Modal
              open={showDownloadAppModal}
              content={
                <div style={{ textAlign: 'center' }}>
                  <div className={classes.downloadBox}>
                    <a
                      href="itms://itunes.apple.com/th/app/pumpkin/id1392855143?mt=8"
                      target="_blank"
                      rel="noopener noreferrer">
                      <Image src={appstore} />
                    </a>
                  </div>
                  <div className={classes.downloadBox}>
                    <a
                      href="market://details?id=com.mi.pumpkin"
                      target="_blank"
                      rel="noopener noreferrer">
                      <Image src={googleplay} />
                    </a>
                  </div>
                  <Text>กรุณาโหลดแอพ เพื่อแลกของรางวัล</Text>
                </div>
              }
              onClose={() => {
                this.setState({ showDownloadAppModal: false });
              }}
            />
          </div>
        </PageContent>
      </Block>
    );
  }
}

export default compose(
  connect(
    state => ({
      rewards: selectRewards(state),
      getRewardsStatus: selectGetRewardsStatus(state)
    }),
    dispatch =>
      bindActionCreators(
        {
          getRewards
        },
        dispatch
      )
  ),
  withStyles(styles)
)(withTheme()(CardDetail));
