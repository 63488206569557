import React from 'react';
import qs from 'qs';
import { Block, Loader } from 'components/atoms';
import CardDetail from './CardDetail';

// REDUX
import { getBrandById } from 'modules/brand/actions';
import { connect } from 'react-redux';
import { selectBrandLists, selectGetBrandById } from 'modules/brand/selectors';
import { bindActionCreators } from 'redux';

// HOC
import withTheme from 'hocs/withTheme';

class BrandList extends React.Component {

  componentDidMount(){
    const {getBrandById ,location} = this.props
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    console.log('query',query)
    getBrandById(query.brand)
  }
  render() {
    const {getBrandByIdStatus ,brandLists , location} =this.props
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    if(getBrandByIdStatus.isPending){
      return  (<Block.MiddleCenter flex={1}>
      <Loader />
    </Block.MiddleCenter>)
    }
    return (
      <CardDetail brandShop={brandLists.find(brand => brand.id === query.brand)}/>
    );
  }
}

const mapStateToProps = state => ({
  getBrandByIdStatus: selectGetBrandById(state),
  brandLists: selectBrandLists(state)
});

export default connect(mapStateToProps, dispatch =>
  bindActionCreators(
    {
      getBrandById
    },
    dispatch
  )
)(withTheme()(BrandList))
